//Editable Number
.editable-number {
  position: relative;
  display: inline-block;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 50px;
    display: grid !important;
    input {
      width: 100px;
    }
  }

  .editable-cell-text-wrapper {
    padding: 0 24px 0 0;
  }
  .editable-cell-icon-check {
    margin-top: 2px;
    position: absolute;
    margin-left: 110px;
  }
  .editable-cell-icon-cross {
    margin-top: 2px;
    position: absolute;
    margin-left: 135px;
  }

  .editable-cell-icon {
    line-height: 18px;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }
  .editable-cell-icon-cross {
    line-height: 28px;
  }

  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
