@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2?v=3.19") format("woff2"),
    url("../fonts/Inter-Regular.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Italic.woff2?v=3.19") format("woff2"),
    url("../fonts/Inter-Italic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter-Medium.woff2?v=3.19") format("woff2"),
    url("../fonts/Inter-Medium.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Inter-SemiBold.woff2?v=3.19") format("woff2"),
    url("../fonts/Inter-SemiBold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-Bold.woff2?v=3.19") format("woff2"),
    url("../fonts/Inter-Bold.woff?v=3.19") format("woff");
}

body {
  font-family: var(--fonts-sans);
  line-height: 1;
  font-size: 16px;
  color: var(--colors-neutral12);
}
