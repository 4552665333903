@import "../../../../../../node_modules/antd/dist/antd.less";
@import "../../../../../assets/common";
@import "../../../../../assets/mixins";
@import "../../../../../assets/variables";

.subscribedUsers {
  .card-title {
    margin-right: 15px;
  }

  &-menu {
    .flexJustified();
    row-gap: 5px;
    flex-wrap: wrap;
    align-items: baseline;
  }

  &-menuSearch.ant-input-group-wrapper {
    width: 300px;
  }

  &-menuBadge {
    background-color: @link-color;
    color: @text-color-inverse;
    padding: 2px 7px;
    border-radius: 10px;
    margin-left: 5px;
    font-size: 0.8rem;
  }

  &-exportButton {
    margin-left: auto;
  }

  tr:hover {
    .editable-cell-icon-pencil {
      display: block;
    }
  }
  .ant-table {
    .ant-table-body {
      .ant-table-tbody {
        .ant-table-row {
          &.hide {
            td {
              .ant-table-row-expand-icon {
                display: none;
              }
            }
          }
          &.show {
            td {
              .ant-table-row-expand-icon {
                margin-left: 10%;
                float: left;
              }
            }
          }
        }
      }
    }
  }
}
