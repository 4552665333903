.domains {
  .domains-table {
    .ant-table-body {
      margin: 0;
    }

    .domains-emails-table {
      .ant-table {
        border: 0;
      }

      td {
        padding: 2px 4px !important;
      }
    }
  }
}
