.logs {
  .card {
    .search-box {
      width: 300px;
    }
    .ant-tag {
      text-transform: capitalize;
    }
  }
}
