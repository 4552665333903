@import "@/assets/variables.less";

.plans-configurations {
  .feature-text {
    padding: 16px 25px;
    font-size: 16px;
    font-weight: 600;
    color: @gray-color-0;
  }
  .feature-role {
    padding-right: 40px;
    font-size: 16px;
    font-weight: 600;
    color: @gray-color-0;
  }
  .sub-feature-text {
    padding: 16px 25px 16px 55px;
    font-family: "Montserrat", system-ui, san-serif, serif;
    font-size: 16px;
    font-weight: normal;
    color: @gray-color-0;
  }
}
