.clustersTab {

  .ant-table {
    border: 1px solid #DFE3E6;
    border-radius: 8px;

    &-thead {
      background-color: #FAFAFA;

      & > tr > th {
        color: #7E868C;
      }
    }

    &-tbody > tr > td {
      border-top: 1px solid #DFE3E6;
    }
  }
}
