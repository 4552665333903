.login {
  height: 100vh !important;
  width: 100vw !important;
  position: relative;
  text-align: center !important;
  .login-div {
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .logo {
      .logo-image {
        height: 64px;
        margin: 16px;
      }
    }
    .main-title {
      font-size: 24px;
    }
    .sub-title {
      font-size: 18px;
    }
    .login-button {
      margin: 20px;
    }
  }
}
