.rolesTab {

  .ant-table {
    border: 1px solid #DFE3E6;
    border-radius: 8px;

    &-thead {
      background-color: #FAFAFA;

      & > tr > th {
        color: #7E868C;
      }
    }

    &-tbody > tr > td {
      border-top: 1px solid #DFE3E6;
    }
  }

  &-roleHeader {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .rolesTab-roleHeaderMore {
        visibility: visible;
      }
    }
  }
  &-roleHeaderLocked {
    margin-right: 0.5rem;
  }
  &-roleHeaderMore {
    visibility: hidden;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    opacity: 0.9;
  }
  &-roleHeaderMenuIcon {
    font-size: 15px;
  }
  &-roleColumnEmptyForLayout {
    visibility: hidden;
  }
}

.ant-table-tbody
  > tr.ant-table-row:hover
  > td.rolesTab-roleColumnEmptyForLayout,
.ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover.td.rolesTab-roleColumnEmptyForLayout {
  background-color: white;
}

