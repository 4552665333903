@import "@/assets/variables.less";

.editable-plan-feature {
  position: relative;
  min-width: 180px;
  height: 60px !important;
  &:hover .edit-icon {
    display: inline-block;
  }
  .h-100 {
    height: 100%;
    .content {
      .loading {
        font-size: 22px;
        color: @primary-color;
      }
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-checkbox-inner {
        width: 25px;
        height: 25px;
        &:after {
          width: 6px;
          height: 13px;
        }
      }
      .check-icon-data {
        color: @primary-color;
        font-size: 18px;
      }
    }
  }
  .edit-icon {
    &:hover {
      color: @primary-color;
    }
    display: none;
    position: absolute !important;
    font-size: 18px;
    top: 3px;
    right: 3px;
  }
  .check-icon {
    &:hover {
      color: @primary-color;
    }
    position: absolute !important;
    font-size: 18px;
    top: 3px;
    right: 30px;
  }
  .cross-icon {
    &:hover {
      color: @primary-color;
    }
    position: absolute !important;
    font-size: 18px;
    top: 3px;
    right: 3px;
  }
}
