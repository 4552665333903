.flex {
  display: flex;
}
.flexCenter {
  .flex();
  justify-content: center;
  align-items: center;
}
.flexColumn {
  .flex();
  flex-direction: column;
}
.flexJustified {
  .flex();
  justify-content: space-between;
}
