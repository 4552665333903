//Heading
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 2px;
}

// spacing code
@spaceamounts: 0, 1, 2, 3, 4, 5, 10, 11, 12, 13, 14, 15, 20, 25, 27, 30, 35, 40,
  45, 50, 60, 70, 80, 90, 100, 110, 120, 130, 150, 200, 250; // Adjust this to include the pixel amounts you need.

each(@spaceamounts, {
  .m-r-@{value} {
    margin-right: @value * 1px !important;
  }
  .m-t-@{value} {
    margin-top: @value * 1px !important;
  }
  .m-b-@{value} {
    margin-bottom: @value * 1px !important;
  }
  .m-l-@{value} {
    margin-left: @value * 1px !important;
  }
  .m-r-@{value}-r {
    margin-right: @value * 1rem !important;
  }
  .m-t-@{value}-r {
    margin-top: @value * 1rem !important;
  }
  .m-b-@{value}-r {
    margin-bottom: @value * 1rem !important;
  }
  .m-l-@{value}-r {
    margin-left: @value * 1rem !important;
  }

  .p-r-@{value}-r {
    padding-right: @value * 1rem !important;
  }
  .p-t-@{value}-r {
    padding-top: @value * 1rem !important;
  }
  .p-b-@{value}-r {
    padding-bottom: @value * 1rem !important;
  }
  .p-l-@{value}-r {
    padding-left: @value * 1rem !important;
  }

  .p-r-@{value} {
    padding-right: @value * 1px !important;
  }
  .p-t-@{value} {
    padding-top: @value * 1px !important;
  }
  .p-b-@{value} {
    padding-bottom: @value * 1px !important;
  }
  .p-l-@{value} {
    padding-left: @value * 1px !important;
  }


  .m-@{value} {
    margin: @value * 1px !important;
  }
  .m-x-@{value} {
    margin-left: @value * 1px !important;
    margin-right: @value * 1px !important;
  }
  .m-y-@{value} {
    margin-top: @value * 1px !important;
    margin-bottom: @value * 1px !important;
  }


  .p-@{value} {
    padding: @value * 1px !important;
  }
  .p-x-@{value} {
    padding-left: @value * 1px !important;
    padding-right: @value * 1px !important;
  }
  .p-y-@{value} {
    padding-top: @value * 1px !important;
    padding-bottom: @value * 1px !important;
  }

});
.m-r-auto {
  margin-right: auto !important;
}
.m-t-auto {
  margin-top: auto !important;
}
.m-b-auto {
  margin-bottom: auto !important;
}
.m-l-auto {
  margin-left: auto !important;
}

.p-r-auto {
  padding-right: auto !important;
}
.p-t-auto {
  padding-top: auto !important;
}
.p-b-auto {
  padding-bottom: auto !important;
}
.p-l-auto {
  padding-left: auto !important;
}

.m-x-auto {
  margin: 0 auto !important;
}

//Text capitalize
.capitalize {
  text-transform: capitalize;
}

//Width
.w-100 {
  width: 100%;
}
.hint-text {
  font-size: 12px;
  color: gray;
}

//Table Action Button
.table-action-button {
  padding: 7px;
}

span.table-action-button {
  padding: 0;
}

//Cursors
.cursor-pointer {
  cursor: default;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

//Custom CSS
.card {
  margin-bottom: 20px;
  border-radius: 6px;
  border-color: lightgray;
  box-shadow: 0 4px 5px -2px rgba(16, 24, 40, 0.08), 0 1px 3px -1px rgba(16, 24, 40, 0.04);

  .ant-card-head-title {
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
}

.ant-alert-banner {
  border-radius: 8px;
}

.ant-modal-body {
  padding-top: 10px;
}

.ant-table {
  border: none;
}

.ant-table-thead {
  tr > th {
    opacity: 0.8;
    background-color: transparent !important;

    &.ant-table-cell-fix-left,
    &.ant-table-cell-fix-right {
      opacity: 1;
      background-color: #fafafa !important;
    }

    &::before {
      display: none;
    }
  }
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}
.ant-table-tbody {
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: #fafafa;
  }
}
.ant-table-tbody > tr.childRow {
  background-color: darken(#fafafa, 3);

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: darken(#fafafa, 3);
  }
}

//Typography
.card-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.sub-title {
  display: inline-block;
  padding-bottom: 5px;
  font-weight: 600;
}

.text-1 {
  opacity: 0.8;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.noStyleLink {
  text-decoration: inherit;
  color: inherit;
}

//Float Classes
.float-left {
  float: left;
}

.float-right {
  float: right;
}

hr {
  opacity: 0.3;
}

.display-inline-block {
  display: inline-block;
}

.display-flex {
  display: flex;
}

.white-space-normal {
  white-space: normal;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
