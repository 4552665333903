.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 50px;
    display: grid !important;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 0;
  }

  .editable-cell-icon-pencil {
    display: none;
    top: 10px;
    position: absolute;
    right: -5px;
  }
  .editable-cell-icon-check {
    margin-top: 2px;
    position: absolute;
    right: 25px;
  }
  .editable-cell-icon-cross {
    margin-top: 2px;
    position: absolute;
    right: 0;
  }

  .editable-cell-icon {
    line-height: 18px;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }
  .editable-cell-icon-cross {
    line-height: 28px;
  }

  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
