@import "~antd/dist/antd.less";
@import "./assets/variables.less";
@import "./assets/common.less";
@import "./assets/mixins.less";
@import "./assets/typography.less";

#root {
  width: 100%;
  height: 100%;
}
